body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url(./fonts/BebasNeueRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'Bebas Neue Bold';
  src: url(./fonts/BebasNeueBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url(./fonts/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat SemiBold';
  src: url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

.MuiDataGrid-menu[role='tooltip'] .MuiPaper-root .MuiList-root {
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.MuiDataGrid-menu[role='tooltip'] .MuiPaper-root .MuiList-root .MuiMenuItem-root {
  width: 95%;
  padding: 6px;
  border: 1px solid rgba(234, 233, 233, 1);
  border-radius: 4px;
  min-width: 120px;
}
