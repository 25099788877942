article {
  position: relative;
  width: 100%;
  height: 51px;
  margin: 5px;
  float: left;
  border: 1px #5e646980 solid;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Montserrat;
  font-weight: bold;
  text-transform: uppercase;
}

article label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  transition: 0.5s ease;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

article input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 51px;
  opacity: 0;
  cursor: pointer;
}

input[type='checkbox']:checked ~ label {
  background-color: #f5a623;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #f5a623;
}
